import React from 'react';
import './App.css';
import linkedinIcon from './assets/linkedin.png'; 
import instagramIcon from './assets/instagram.png';
import hercodeLogo from './assets/hercode.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="hercode-logo">
          <img src={hercodeLogo} alt="HerCode Logo" />
        </div>
      </header>
      <div className='main'> 
        <h1>Coming Soon</h1>
        <a href="mailto:team@hercode.dev" className="contact-button">GET IN TOUCH WITH US</a>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/hercode-community/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
          <a href="https://www.instagram.com/hercode_community?igsh=MWg5YzZhZGkwN3V4ZA%3D%3D" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
      </div> 
      <footer>All Rights Reserved 2024</footer>
    </div>
  );
}

export default App;
